import React, { useState } from "react";
import "./App.css";
import bible from './img/bible6.jpg'
function App() {
  // Properties
  const [showResults, setShowResults] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState(0);

  const questions = [
    {
      text: "Hérode a été empereur au temps de Jésus.",
      options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true },
        
      ],
    },
    {
      text: "Joseph, le fiancé de Marie était un des fils de Jacob.",
      options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true },
        
      ],
    },
    {
      text: "Jean a été appelé Jean Baptiste à cause de la mission importante que Dieu lui avait confié.",
      options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true },
        
      ],
    },
    {
      text: "Marie la mère de Jésus était descendante de David.",
      options: [
        { id: 0, text: "Vrai", isCorrect: true },
        { id: 1, text: "Faux", isCorrect: false },
        
      ],
    },
    {
      text: "Jacques fils de Zébédée a été le deuxième martyr chrétien mentionné dans la Bible.",
      options: [
        { id: 0, text: "Vrai", isCorrect: true },
        { id: 1, text: "Faux", isCorrect: false },
      ],
    },
    {
      text: "Jean, apôtre de Jésus, était aussi fils de Zébédée.",
      options: [
        { id: 0, text: "Vrai", isCorrect: true },
        { id: 1, text: "Faux", isCorrect: false },
      ],
    },
    {
      text: "Pierre a été le premier à être nommé apôtre.",
      options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true },
      ],
    },
    {
      text: "Parmi les apôtres ; c’est André qui fut le premier à connaitre Jésus.",
      options: [
        { id: 0, text: "Vrai", isCorrect: true },
        { id: 1, text: "Faux", isCorrect: false },
      ],
    },
    {
      text: "Caïphe a été grand prêtre en Israël, au temps de Jésus, pendant quatre ans.",
      options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true },
      ],
    },
    {
      text: "Pilate était le roi Romain qui a condamné Jésus.",
      options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true },
      ],
    },
    {
      text: "Nicodème fut cet homme à qui Jésus avait enseigné qu’il fallait naitre de nouveau pour entrer dans le Royaume de Dieu.",
      options: [
        { id: 0, text: "Vrai", isCorrect: true },
        { id: 1, text: "Faux", isCorrect: false },
      ],
    },
    {
      text: "Zacharie, père de Jean – Baptiste devint muet à cause du fait qu’il avait émis un doute lorsque l’ange Gabriel lui annonça qu’il aura un fils.",
      options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true },
      ],
    },
    {
      text: "Elisabeth, la mère de Jean – Baptiste était la tante de Marie.",
      options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true },
      ],
    },
    {
      text: "César Auguste était un titre que Octave a été le premier à porter.",
      options: [
        { id: 0, text: "Vrai", isCorrect: true },
        { id: 1, text: "Faux", isCorrect: false },
      ],
    },
    {
      text: "Thaddée était l’un des douze apôtre.",
      options: [
        { id: 0, text: "Vrai", isCorrect: true },
        { id: 1, text: "Faux", isCorrect: false },
      ],
    },
    {
      text: "Tibère César fut le deuxième empereur de Rome et c’est sous son règne que Jean Baptiste prêcha.",
      options: [
        { id: 0, text: "Vrai", isCorrect: true },
        { id: 1, text: "Faux", isCorrect: false },
      ],
    },
    {
      text: "Marie sœur de Marthe, était de Béthanie.",
      options: [
        { id: 0, text: "Vrai", isCorrect: true },
        { id: 1, text: "Faux", isCorrect: false },
      ],
    },
    {
      text: "Anne était Grand prêtre au temps de Jésus.",
      options: [
        { id: 0, text: "Vrai", isCorrect: true },
        { id: 1, text: "Faux", isCorrect: false },
      ],
    },
    {
      text: "A Jérusalem, il y avait une femme nommée Marthe, dont Jésus avait ressuscité le frère appelé Lazare .",
      options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true },
      ],
    },
    {
      text: "La femme de laquelle Jésus chassa sept démons s’appelait Jeanne femme de Chuza.",
      options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true },
      ],
    },
    {
      text: "Lequel de ces hommes a eu l’oreille coupée par Pierre et guérie par le Seigneur Jésus?",
      options: [
        { id: 0, text: "Maléléel", isCorrect: false },
        { id: 1, text: "Malchus", isCorrect: true },
        { id: 2, text: "Manahem", isCorrect: false },
      ],
    },

  ];

  // Helper Functions

  /* A possible answer was clicked */
  const optionClicked = (isCorrect) => {
    // Increment the score
    if (isCorrect) {
      setScore(score + 1);
    }

    if (currentQuestion + 1 < questions.length) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      setShowResults(true);
    }
  };

  /* Resets the game back to default */
  const restartGame = () => {
    setScore(0);
    setCurrentQuestion(0);
    setShowResults(false);
  };

  return (
    <div className="App" >
     <div className="back">
      {/* 1. Header  */}
      <div className="header">
        LA BIBLE QUIZ
      </div>
      

      {/* 2. Current Score  */}
      <div className="score">Score: {score}</div>

      {/* 3. Show results or show the question game  */}
      {showResults ? (
        /* 4. Final Results */
        <div className="final-results">
          <div className="final">
              <h1>
                Final Results  
              </h1>
            
              <h2>
                {score} / {questions.length}
              </h2>
          </div>
          <div className="resultat">
          <button onClick={() => restartGame()}>Restart game</button>
          <button onClick={() => restartGame()} disabled={true}>Passer au stade suivant</button>
          </div>
        </div>
      ) : (
        /* 5. Question Card  */
        <div className="question-card">
          {/* Current Question  */}
          <div className="question-number">
            <div><h2>
            Question: N° {currentQuestion + 1} sur {questions.length}
          </h2></div>
            <div>timer</div>
          </div>
          <div className="question">
            {questions[currentQuestion].text}
          </div>

          {/* List of possible answers  */}
          <ul>
            {questions[currentQuestion].options.map((option) => {
              return (
                <li
                  key={option.id}
                  onClick={() => optionClicked(option.isCorrect)}
                >
                  {option.text}
                </li>
              );
            })}
          </ul>
        </div>
      )}
      </div>
    </div>
  );
}

export default App;
